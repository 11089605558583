// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start();
require("turbolinks").start();
require("@rails/activestorage").start();
require("channels");
require('jquery');
require("@fortawesome/fontawesome-free");
require('bootstrap');
require('inputmask');
require('../nested-forms/add_fields');
require('../nested-forms/remove_fields');
//require('jquery.extendext');
//require('doT');
//require('query-builder');

document.addEventListener("turbolinks:load", () => {
    $('[data-toggle="tooltip"]').tooltip()
    $('.datepicker').datepicker({
        format: 'yyyy-mm-dd'
    });
})





